<template>
  <div class="echarts-box">
    <div id="main" class="main"></div>
  </div>
</template>
<script>
export default {
  name: "Echarts",

  props: {
    signList: {
      type: Array,
    },
  },

  created() {
    console.log("22233333", this.signList);
  },

  mounted() {
    this.myEcharts();
  },

  methods: {
    myEcharts() {
      var myChart = this.$echarts.init(document.getElementById("main"));
      //配置图表
      const colors = ["#5470C6", "#EE6666"];
      var option = {
        title: {
          text: "体重(kg)",
        },
        color: colors,
        tooltip: {
          trigger: "none",
          axisPointer: {
            type: "cross",
          },
        },
        legend: {},
        grid: {
          // top: 70,
          bottom: 50,
          left: 0,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            // prettier-ignore
            data: ['2016-1', '2016-2', '2016-3', '2016-4', '2016-5', '2016-6', '2016-7', '2016-8', '2016-9', '2016-10', '2016-11', '2016-12']
          },
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: colors[0],
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Precipitation(2016)",
            type: "line",
            smooth: true,
            emphasis: {
              focus: "series",
            },
            data: [
              3.9,
              5.9,
              11.1,
              18.7,
              48.3,
              69.2,
              231.6,
              46.6,
              55.4,
              18.4,
              10.3,
              0.7,
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts-box {
  margin-top: 30px;
  width: 100%;
  height: 455px;
  background-color: #f7f9fb;
}
</style>